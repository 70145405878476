<template>
	<div>
		<h2>{{ $t("courrier_type.title") }}</h2>
				
		<CustomTable
			ref="table"
            id_table="liste_courrier_contrat"
            :busy.sync="table_busy"
            primaryKey="model_id"
            :items="courrier_type"
        />
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
    import ModelMixin from "@/mixins/Model.js";
    import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: 'ContractCourrierType',
		mixins: [ModelMixin, ShutterContract, Shutter, Navigation],
		props: ['contract_id'],
		data () {
			return {
				table_busy: false,
				courrier_type: [],
				events_tab: {
                    'TableAction::goToAddCourrier': this.openAjout,
                    'TableAction::goToEditCourrier': (model) => {
		            	this.editModel(model.model[0].model_id, model.model[0].type.modeltype_template) 
		            },
		            'TableAction::goToPdfCourrier': (model) => {
		            	this.goToPrintCourrier(model.model[0].model_id, model.model[0].model_label)
		            },
		            'TableAction::goToDeleteCourrierFiche': (models) => {
		            	this.goToDeleteCourrier(models)
		            }
                },
				reloadMethod: 'refreshTable'
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.table_busy = true
                this.courrier_type = await this.loadAllModel(this.contract_id, ['contract_generated', 'contract_adresses_generated'])
                this.table_busy = false
			},

			openAjout() {
				this.setupCourrierTypeAjout(this.contract_id)
			},

			editModel(model_id, modeltype_template) {
				this.setupCourrierTypeEdit(this.contract_id, model_id, modeltype_template)
			},

			async goToPrintCourrier(model_id, model_name) {
				let result = await this.printCourrier(model_id, model_name)
                if(result == null){
                	this.failureToast('monte.print_error')					
                }
                EventBus.$emit("TableAction::stopSpin")
			},

			refreshTable() {
				this.init_component()
			},

			async goToDeleteCourrier(models) {
				const models_ids = models.map(model => model.model_id)
				await this.deleteModel(models_ids);
				EventBus.$emit('TableAction::stopSpin')
				this.$refs.table.unselectAll()
				this.init_component()
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>